<template>
  <section class="addin">
    <center>
    <b-img
      fluid
      class="mb-2 headerLogo"
      src="img/login-150px.png"
    ></b-img></center>
    <br />
    <br />
    Detta SEFOS tillägg är ersatt av SEFOS for M365.
    
    <br /><br />
    SEFOS är fortfarande tillgängligt via:    
    <ul>
      <li><b>SEFOS for M365</b>
        <ul>
          <li>Teams</li>
          <li>Outlook</li>
          <li>M365 applikationen</li>
        </ul>
      </li>
      <li><b>SEFOS for WEB</b></li>
    </ul>
    Klicka här för att använda SEFOS i webbläsaren.
    <a @click="openWeb"
      class="btn btn-fill btn-block mt-2 btn-primary"
    >
    SEFOS for WEB
  </a>
  <br />
  Mer information hur man använder SEFOS
  
  <a @click="OpenForuM"
  class="btn btn-fill btn-block mt-2 btn-primary"
>
Manualer och hjälpguider
</a>
  </section>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
    openWeb(){
      window.open(this.user.hostname.replace("/server/rest",""));
    },
    OpenForuM(){
      window.open("https://forum.sefos.se/anv%C3%A4nda-sefos-965740");
    }
  },
  mounted() {
  },
};
</script>
<style></style>
